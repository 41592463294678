import { useState } from "react";
import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import ClearIcon from "@mui/icons-material/Clear";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { GridFilterItem } from "@mui/x-data-grid";
import * as React from "react";

interface CustomHomeGridFilterProps {
  filters: GridFilterItem[];
  setFilters: React.Dispatch<React.SetStateAction<GridFilterItem[]>>;
}

const CustomHomeGridFilter: React.FC<CustomHomeGridFilterProps> = ({
  filters,
  setFilters,
}) => {
  const [currentFilter, setCurrentFilter] = useState({
    field: "name",
    operator: "contains",
    value: "",
  });
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  return (
    <Box
      p={2}
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={1.5}
      width="320px"
      maxHeight="560px"
      overflow="auto"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={1.5}
        width="100%"
        mb={filters.length ? 2 : 0}
      >
        {filters.map((filter, index) => {
          return (
            <Box key={index} width="100%" display="flex" gap={0.5}>
              <button
                onClick={() => {
                  setFilters((p) => p.filter((_, i) => i !== index));
                }}
                style={{
                  border: "1px solid lightgray",
                  borderRadius: "4px",
                }}
              >
                <ClearIcon fontSize="small" color="error" />
              </button>
              <Accordion
                sx={{
                  width: "90%",
                  // flexGrow: 1,
                  boxShadow: 0,
                  border: "1px solid lightgray",
                }}
              >
                <AccordionSummary
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textWrap: "nowrap",
                    height: "12px",
                  }}
                >
                  Filter {index + 1}
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      label="Column"
                      value={filter.field}
                      disabled
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      label="Operator"
                      value={filter.operator}
                      disabled
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      label="Value"
                      value={filter.value}
                      disabled
                    />
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            </Box>
          );
        })}
      </Box>
      <FormControl fullWidth>
        <InputLabel id="filterColumn">Column</InputLabel>
        <Select
          size="small"
          label="Column"
          id="filterField"
          labelId="filterColumn"
          defaultValue="name"
          value={currentFilter.field}
          onChange={(e) => {
            setCurrentFilter({
              field: e.target.value,
              operator: filterOptions[e.target.value][0],
              value: "",
            });
            setSelectedValues([]);
          }}
        >
          <MenuItem value="name">Name</MenuItem>
          <MenuItem value="endDate">End Date</MenuItem>
          <MenuItem value="whatsappBalance">Whatsapp Balance</MenuItem>
          <MenuItem value="smsBalance">SMS Balance</MenuItem>
          <MenuItem value="emailBalance">Email Balance</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="filterOperator">Operator</InputLabel>
        <Select
          size="small"
          label="Operator"
          labelId="filterOperator"
          defaultValue={filterOptions[currentFilter.field][0]}
          value={currentFilter.operator}
          onChange={(e) => {
            setCurrentFilter((p) => ({
              ...p,
              operator: e.target.value,
              value: "",
            }));
            setSelectedValues([]);
          }}
        >
          {filterOptions[currentFilter.field]?.map((item, i) => (
            <MenuItem key={i} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        {["is any of", "is between", "is not between"].includes(
          currentFilter.operator
        ) ? (
          <Box display="flex" flexDirection="column" gap={1}>
            <Box
              display="flex"
              flexWrap="wrap"
              gap={0.5}
              sx={{paddingInline:selectedValues.length ? 2 : 0}}
            >
              {selectedValues.map((value, index) => (
                <Chip
                  size="small"
                  key={index}
                  label={value}
                  onDelete={() => {
                    setSelectedValues((values) =>
                      values.filter((v) => v !== value)
                    );
                  }}
                />
              ))}
            </Box>
            <TextField
              helperText="Press Enter after typing value"
              size="small"
              id="filterValue"
              label={"endDate" === currentFilter.field ? "" : "Value"}
              type={
                "name" === currentFilter.field
                  ? "text"
                  : "endDate" === currentFilter.field
                  ? "datetime-local"
                  : "number"
              }
              variant="outlined"
              value={currentFilter.value}
              onChange={(e) => {
                setCurrentFilter((p) => ({
                  ...p,
                  value: e.target.value,
                }));
              }}
              onKeyDown={(event) => {
                if (
                  event.key === "Enter" &&
                  currentFilter.value.trim() !== ""
                ) {
                  setSelectedValues([
                    ...selectedValues,
                    currentFilter.value.trim(),
                  ]);
                  setCurrentFilter((p) => ({ ...p, value: "" }));
                }
              }}
            />
          </Box>
        ) : (
          <TextField
            size="small"
            id="filterValue"
            label={"endDate" === currentFilter.field ? "" : "Value"}
            type={
              "name" === currentFilter.field
                ? "text"
                : "endDate" === currentFilter.field
                ? "datetime-local"
                : "number"
            }
            variant="outlined"
            disabled={["is empty", "is not empty"].includes(
              currentFilter.operator
            )}
            sx={{
              display: ["is empty", "is not empty"].includes(
                currentFilter.operator
              )
                ? "none"
                : "",
            }}
            value={currentFilter.value}
            onChange={(e) => {
              setCurrentFilter((prev) => ({
                ...prev,
                value: e.target.value,
              }));
            }}
          />
        )}
      </FormControl>
      <Box width="100%" display="flex" justifyContent="space-between">
        <Button
          size="small"
          variant="contained"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 0.5,
            fontWeight: 550,
            boxShadow: 0,
          }}
          onClick={() => {
            setFilters([]);
          }}
        >
          <CancelIcon fontSize="small" />
          Remove All
        </Button>
        <Button
          size="small"
          variant="contained"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 0.5,
            fontWeight: 550,
            boxShadow: 0,
          }}
          onClick={() => {
            if (
              currentFilter.value.length ||
              ["is empty", "is not empty"].includes(currentFilter.operator) ||
              selectedValues.length
            ) {
              setFilters((p) => [
                ...p,
                {
                  ...currentFilter,
                  value: ["is any of", "is between", "is not between"].includes(
                    currentFilter.operator
                  )
                    ? selectedValues.join(",")
                    : ["is empty", "is not empty"].includes(
                        currentFilter.operator
                      )
                    ? "."
                    : currentFilter.value,
                },
              ]);
              setSelectedValues([]);
              setCurrentFilter({
                field: currentFilter.field,
                operator: currentFilter.operator,
                value: "",
              });
            }
          }}
        >
          <AddIcon fontSize="small" /> Apply Filter
        </Button>
      </Box>
    </Box>
  );
};

export default CustomHomeGridFilter;

const filterOptions: Record<string, string[]> = {
  name: [
    "contains",
    "equals",
    "starts with",
    "ends with",
    "is empty",
    "is not empty",
    "is any of",
  ],
  endDate: [
    "is",
    "is not",
    "is after",
    "is on or after",
    "is before",
    "is on or before",
    "is empty",
    "is not empty",
    "is between",
    "is not between",
  ],
  whatsappBalance: [
    "=",
    "!=",
    "<",
    "<=",
    "is empty",
    "is not empty",
    "is any of",
    "is between",
    "is not between",
  ],
  smsBalance: [
    "=",
    "!=",
    "<",
    "<=",
    "is empty",
    "is not empty",
    "is any of",
    "is between",
    "is not between",
  ],
  emailBalance: [
    "=",
    "!=",
    "<",
    "<=",
    "is empty",
    "is not empty",
    "is any of",
    "is between",
    "is not between",
  ],
};
